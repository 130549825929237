import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { Component } from "react"
import Logo from "../images/helmarts_logo.svg"

class Header extends Component {
  state = {
    offcanvas: false,
  }

  handleClick = () => {
    this.setState(state => ({ offcanvas: !state.offcanvas }))
  }

  render() {
    return (
      <div
        className={`${
          this.state.offcanvas ? "offcanvas-menu" : ""
        } position-relative`}
      >
        <div className="site-overlay" />

        <div className="site-navbar-wrap js-site-navbar bg-white position-static">
          <div className="container">
            <div className="site-navbar bg-light">
              <div className="row align-items-center">
                <div className="col-1">
                  <span className="mb-0 site-logo">
                    <Link to="/">
                      <img
                        width={150}
                        height={53}
                        src={Logo}
                        alt="Helmart's Foodtruck"
                      />
                    </Link>
                  </span>
                </div>
                <div className="col-11 position-static">
                  <nav className="site-navigation" role="navigation">
                    <div className="container">
                      <div className="d-inline-block d-lg-none ml-md-0 mr-auto py-3">
                        <button
                          onClick={this.handleClick}
                          className="site-menu-toggle js-menu-toggle text-black"
                        >
                          <span className="icon-menu h3"></span>
                        </button>
                      </div>

                      <ul className="site-menu js-clone-nav d-none d-lg-block">
                        <div className="site-mobile-menu-header d-block d-lg-none mb-4">
                          <div className="site-mobile-menu-close mt-3">
                            <button
                              className="icon-close2 js-menu-toggle"
                              onClick={this.handleClick}
                            ></button>
                          </div>
                        </div>
                        <li>
                          <Link activeClassName="active" to="/">
                            Food Truck
                          </Link>
                        </li>
                        <li>
                          <Link activeClassName="active" to="/speisekarte/">
                            Speisekarte
                          </Link>
                        </li>
                        {/* <li><Link activeClassName="active" to="/catering/" partiallyActive={true}>Events + Catering</Link></li> */}
                        <li>
                          <Link activeClassName="active" to="/kontakt/">
                            Kontakt
                          </Link>
                        </li>
                        <li>
                          <a
                            rel="noopener noreferrer nofollow"
                            href="https://www.facebook.com/helmartsfoodtruck/"
                            target="_blank"
                            className="social facebook"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 448 512"
                            >
                              <path
                                fill="#3b5998"
                                d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z"
                              />
                            </svg>
                            <span className="d-inline d-lg-none">Facebook</span>
                          </a>
                        </li>
                        <li>
                          <a
                            rel="noopener noreferrer nofollow"
                            href="https://www.instagram.com/helmarts_food_truck/"
                            target="_blank"
                            className="social instagram"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 448 512"
                            >
                              <path
                                fill="#e4405f"
                                d="M224,202.66A53.34,53.34,0,1,0,277.36,256,53.38,53.38,0,0,0,224,202.66Zm124.71-41a54,54,0,0,0-30.41-30.41c-21-8.29-71-6.43-94.3-6.43s-73.25-1.93-94.31,6.43a54,54,0,0,0-30.41,30.41c-8.28,21-6.43,71.05-6.43,94.33S91,329.26,99.32,350.33a54,54,0,0,0,30.41,30.41c21,8.29,71,6.43,94.31,6.43s73.24,1.93,94.3-6.43a54,54,0,0,0,30.41-30.41c8.35-21,6.43-71.05,6.43-94.33S357.1,182.74,348.75,161.67ZM224,338a82,82,0,1,1,82-82A81.9,81.9,0,0,1,224,338Zm85.38-148.3a19.14,19.14,0,1,1,19.13-19.14A19.1,19.1,0,0,1,309.42,189.74ZM400,32H48A48,48,0,0,0,0,80V432a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V80A48,48,0,0,0,400,32ZM382.88,322c-1.29,25.63-7.14,48.34-25.85,67s-41.4,24.63-67,25.85c-26.41,1.49-105.59,1.49-132,0-25.63-1.29-48.26-7.15-67-25.85s-24.63-41.42-25.85-67c-1.49-26.42-1.49-105.61,0-132,1.29-25.63,7.07-48.34,25.85-67s41.47-24.56,67-25.78c26.41-1.49,105.59-1.49,132,0,25.63,1.29,48.33,7.15,67,25.85s24.63,41.42,25.85,67.05C384.37,216.44,384.37,295.56,382.88,322Z"
                              />
                            </svg>
                            <span className="d-inline d-lg-none">
                              Instagram
                            </span>
                          </a>
                        </li>
                        <li>
                          <a
                            rel="noopener noreferrer nofollow"
                            href="https://wa.me/436606933305"
                            target="_blank"
                            className="social whatsapp"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="icon icon-tabler icon-tabler-brand-whatsapp"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              stroke-width="2"
                              stroke="currentColor"
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              />
                              <path d="M3 21l1.65 -3.8a9 9 0 1 1 3.4 2.9l-5.05 .9" />
                              <path d="M9 10a.5 .5 0 0 0 1 0v-1a.5 .5 0 0 0 -1 0v1a5 5 0 0 0 5 5h1a.5 .5 0 0 0 0 -1h-1a.5 .5 0 0 0 0 1" />
                            </svg>
                            <span className="d-inline d-lg-none">
                              Whatsapp
                            </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header

/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import Logo from '../images/helmarts_logo_rand.svg';

import React, { Component } from "react"
import { Link } from "gatsby"

class Footer extends Component {

  constructor(props) {
    super(props);
    this.dismissCookieNotification = this.dismissCookieNotification.bind(this);
  }

  // let hideCookie = false;
  dismissCookieNotification() {
    if (!document) return true;
    var d = new Date();
    d.setTime(d.getTime() + (30*24*60*60*1000));
    var expires = "expires="+ d.toUTCString();
    document.cookie = 'cookie_banner_ack=ack;' + expires + ";path=/";
    this.setState({
      hideCookie: true
    });
  }

  componentDidMount() {
    this.setState({
      hideCookie: document.cookie.includes('cookie_banner_ack=ack')
    });
  }

  render() {
    return (
      <footer className="page-footer font-small blue pt-4">
        <div className="container-fluid text-center text-md-left">
          <div className="row">
            <div className="col-12 col-sm-6 col-lg-3 footer-logo">
              <img width={177} height={138} src={Logo} alt="Helmart's Foodtruck" />
            </div>
      
            <div className="col-12 col-sm-6 col-lg-3 mb-3">
      
              <h5 className="text-uppercase">Helmart's</h5>
      
              <ul className="list-unstyled">
                <li>
                  <Link to="/speisekarte/">Speisekarte</Link>
                </li>
                <li>
                  <Link to="/kontakt/">Kontakt</Link>
                </li>
                <li>
                  <Link rel="nofollow" to="/datenschutz/">Datenschutz</Link>
                </li>
                <hr/>
                <li>
                  <a rel="noopener noreferrer nofollow" target="_blank" href="https://www.facebook.com/helmartsfoodtruck/">Facebook</a>
                </li>
                <li>
                  <a rel="noopener noreferrer nofollow" target="_blank" href="https://www.instagram.com/helmarts_food_truck/">Instagram</a>
                </li>
                <li>
                  <a rel="noopener noreferrer nofollow" target="_blank" href="https://wa.me/436606933305">Whatsapp</a>
                </li>

              </ul>
      
            </div>

            <div className="col-12 col-sm-6 col-lg-3 mb-3">
      
              <h5 className="text-uppercase">Kontakt</h5>
      
              Helmarts e.U.<br/>
              Thürnlhofstraße 5/6/606<br/>
              1110 Wien<br/>
              +43 660 69 333 05<br/>
              <a href="mailto:office@helmarts.at">office@helmarts.at</a>
      
            </div>

            <div className="col-12 col-sm-6 col-lg-3 mb-3">
      
              <h5 className="text-uppercase">Impressum 
              (<a href="https://firmen.wko.at/helmarts-eu/wien/?firmaid=ae026dff-fd69-43fb-af31-b0743181f197" rel="nofollow" target="_blank" >WKO</a>)</h5>
      
              Geschäftsführer: Patrick Graf<br/>
              Handelsgericht Wien<br/>
              UID: ATU75148724<br/>
              Firmenbuchnr.: FN 525059 x<br/>
              Behörde gem. E-Commerce Gesetz:<br/>
              Mag. Bezirksamt des 11. Bezirkes
            </div>
      
          </div>
      
        </div>

        <div className={'cookie-notification-container ' + (this.state && this.state.hideCookie ? ' d-none' : '')} rel="cookie-notification"><div className="cookie-notification"><span className="cookie-notification-copy">
        Durch die Nutzung dieser Website stimmen Sie unseren <Link rel="nofollow" to="/datenschutz/" className="text-white">Cookie-Richtlinien</Link> zu.
          </span><button className="dismiss-button" rel="dismiss-cookie-notification" onClick={this.dismissCookieNotification}>Schließen</button></div></div>
      
      </footer>
    )
  }
}

export default Footer
